<template>
  <div>
    <VueSkeletonLoader
      v-if="loading"
      animation="wave"
      class="mb-4"
      :color="'#bec3d8'"
      :height="40"
      :radius="'2'"
      type="rect"
    />
    <div v-else class="report-address-actions__wrapper">
      <div class="report-address-actions__left">
        <div class="report-address-actions__title">
          {{ title }}
        </div>
        <div v-if="info.isMonitoring">
          <MonitoredButton />
        </div>
      </div>

      <div class="report-address-actions__right">
        <div
          v-if="isAddToMonitoringPossible"
          class="report-address-actions__monitoring-button-wrapper"
        >
          <AddToMonitoringButton
            :address="info.address"
            :icon-only="isMobile"
          />
        </div>
        <div
          v-if="flagsData.length"
          class="report-address-actions__alerts-wrapper"
        >
          <AlertsPopupBell
            :flags-data="flagsData"
            :loading="calculationLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import VueSkeletonLoader from 'skeleton-loader-vue'
import MonitoredButton from '@/pages/report/components/info/MonitoredButton'
import AlertsPopupBell from '@/pages/report/components/info/AlertsPopupBell'
import AddToMonitoringButton from '@/pages/report/components/info/AddToMonitoringButton'
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'TopAddressActions',
  components: {
    VueSkeletonLoader,
    MonitoredButton,
    AlertsPopupBell,
    AddToMonitoringButton,
  },
  mixins: [deviceWidthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    calculationLoading: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    title() {
      if (this.info.addressType == 'address') return 'Address Risk Report'
      if (this.info.addressType == 'contract') return 'Smart Contract Report'
      if (this.info.addressType == 'tokenContract')
        return 'Token Contract Report'
      return ''
    },
    flagsData() {
      return this.info.flagsData || []
    },
    isAddToMonitoringPossible() {
      return this.info.addressType === 'address' && !this.info.isMonitoring
    },
  },
}
</script>
<style>
.report-address-actions__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.report-address-actions__title {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}
.report-address-actions__left,
.report-address-actions__right {
  display: flex;
  align-items: center;
  gap: 24px;
}

.report-address-actions__monitoring-button-wrapper .gl-button {
  height: 44px;
  min-width: 44px;
  width: 243px;
  font-weight: 600;
  line-height: 24px;
  text-transform: none;
}
.report-address-actions__monitoring-button-wrapper .gl-button .gl-icon {
  margin-bottom: 2px;
}

@media (max-width: 1200px) {
  .report-address-actions__title {
    font-size: 30px;
    line-height: 48px;
  }
}

@media (max-width: 767px) {
  .report-address-actions__title {
    font-size: 24px;
    line-height: 32px;
  }
  .report-address-actions__left {
    flex-direction: column;
    align-items: flex-start;
  }
  .report-address-actions__monitoring-button-wrapper .gl-button {
    width: 44px;
  }
  .report-address-actions__wrapper {
    align-items: flex-start;
    gap: 16px;
  }

  .report-address-actions__left,
  .report-address-actions__right {
    gap: 16px;
  }

  .report-address-actions__right {
    align-items: flex-start;
  }
}
</style>
