<template>
  <div class="report-tables__wrapper">
    <SectionBlock
      v-if="loading"
      body-white
      class="report-tables__empty"
    >
      <template #title />
      <template #body>
        <div class="report-tables__loader-wrapper">
          <gl-loader
            height="100%"
            spin-blue-view
          />
        </div>
      </template>
    </SectionBlock>
    <div v-else>
      <SectionBlock
        v-if="showSofTable"
        body-white
        class="report-tables__sof"
      >
        <template #title>
          Source of Funds Transactions
        </template>
        <template #body>
          <SourcesTable
            :currencies="currenciesByDirection.incoming"
            :data="filteredSofSources"
            :essential="essential"
          />
        </template>
      </SectionBlock>
      <SectionBlock
        v-if="showUofTable"
        body-white
        class="report-tables__uof"
      >
        <template #title>
          Use of Funds Transactions
        </template>
        <template #body>
          <SourcesTable
            :currencies="currenciesByDirection.outgoing"
            :data="filteredUofSources"
            :essential="essential"
          />
        </template>
      </SectionBlock>
      <SectionBlock
        v-if="!filteredSofSources.length && !filteredUofSources.length"
        body-white
        class="report-tables__empty"
      >
        <template #title />
        <template #body>
          <div class="report-tables__empty-error">
            <gl-icon
              :height="16"
              name="info"
              :width="16"
            />
            {{ noTxErrorText }}
          </div>
        </template>
      </SectionBlock>
    </div>
  </div>
</template>

<script>
// Components
import GlLoader from '@/components/gl-loader'
import SectionBlock from '@/components/gl-section-block'
import GlIcon from '@/components/gl-icon'
import SourcesTable from '@/pages/report/components/table/SourcesTable'

// Utils
import appConfig from '@/utils/appConfig'
import { filterSourceResultByEntityType } from '@/utils/report-data-formatter'
// Vuex
import { mapState, mapGetters } from 'vuex'

export default {
  components: { GlLoader, SectionBlock, GlIcon, SourcesTable },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    reportType: {
      type: String,
      default: 'address',
    },
    pureSources: {
      type: Object,
      default: () => ({}),
    },
    currenciesByDirection: {
      type: Object,
      default: () => ({}),
    },
    essential: {
      type: Boolean,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinType']),
    ...mapGetters('directions', [
      'getActivePartBar',
      'getActiveTypeEntityItem',
      'getActiveExposurePart',
      'getExposurePartOptions',
    ]),
    SRDirection() {
      return appConfig.VUE_APP_SCORE_REQUEST_DIRECTION
    },
    filteredSofSources() {
      return (
        this.filterSourceResultByEntityType(
          this.pureSources?.incoming,
          this.getActiveTypeEntityItem,
          this.getActivePartBar.value,
        ) || []
      )
    },
    filteredUofSources() {
      return (
        this.filterSourceResultByEntityType(
          this.pureSources?.outgoing,
          this.getActiveTypeEntityItem,
          this.getActivePartBar.value,
        ) || []
      )
    },
    showSofTable() {
      return (
        this.filteredSofSources?.length &&
        (this.SRDirection == 0 || this.SRDirection == 2) &&
        (this.getActiveExposurePart.value === 'full' ||
          this.getActiveExposurePart.value === 'incoming')
      )
    },
    showUofTable() {
      return (
        this.filteredUofSources?.length &&
        (this.SRDirection == 1 || this.SRDirection == 2) &&
        (this.getActiveExposurePart.value === 'full' ||
          this.getActiveExposurePart.value === 'outgoing')
      )
    },
    noTxErrorText() {
      return 'There are no transactions on this address'
    },
  },
  methods: { filterSourceResultByEntityType },
}
</script>
<style>
.report-tables__loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px 0;
}

.report-tables__empty,
.report-tables__sof,
.report-tables__uof {
  margin-bottom: 24px;
}

.report-tables__empty-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.report-tables__wrapper .gl-section-block__body-wrapper {
  padding: 4px 0;
}
</style>
