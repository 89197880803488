<template>
  <div
    ref="barsContainer"
    class="gl-scoring-bar-chart__wrapper"
    :class="{ 'gl-scoring-bar-chart__two-line-view': twoLineView }"
  >
    <VueSkeletonLoader
      v-if="loading"
      animation="wave"
      :color="'#bec3d8'"
      :height="20"
      :radius="'2'"
      type="rect"
    />
    <div v-else-if="dataTableResult.length">
      <transition-group
        appear
        name="slide"
      >
        <div
          v-for="(item, i) in dataTableResult"
          :key="i"
          class="risk-exposure__table"
        >
          <div
            :class="classesForRiskExposureRow(item)"
            @click="typeEntityItemClick(item)"
          >
            <div class="risk-exposure__row-badge">
              <GlBadgeInfo
                :entity-id="item.entityId"
                :text="formatLabelName(item)"
                :value="getItemScore(item)"
              />
              <div class="risk-exposure__row-info-values" v-if="!essential">
                <div
                  v-if="item.formattedAmount"
                  class="risk-exposure__row-info-values-amount"
                >
                  {{ item.formattedAmount }}
                </div>
                <div
                  class="risk-exposure__row-info-values-price"
                  :class="{
                    'risk-exposure__row-info-values-amount':
                      !item.formattedAmount,
                  }"
                >
                  {{
                    item.amountCur
                      ? formatByPrice(item.amountCur)
                      : item.price
                        ? formatByPrice(item.price)
                        : ''
                  }}
                </div>
              </div>
            </div>
            <div class="risk-exposure__row-info">
              <div class="risk-exposure__row-info-percents">
                <div
                  v-if="item.deepDepthShare"
                  v-popover:tooltip.top="'Indirect'"
                  class="risk-exposure__row-info-percents-item risk-exposure__row-info-percents-item--indirect"
                  :style="`width: ${calculateWidth(
                    item,
                    item.deepDepthShare * 100,
                    percentsInPixels,
                  )}`"
                  @click="typeEntityItemClick(item)"
                >
                  {{ formatShare(roundShare(item.deepDepthShare)) }}
                </div>
                <div
                  v-if="item.generalShare"
                  v-popover:tooltip.top="'Direct'"
                  class="risk-exposure__row-info-percents-item risk-exposure__row-info-percents-item--direct"
                  :style="`width: ${calculateWidth(
                    item,
                    item.generalShare * 100,
                    percentsInPixels,
                  )}`"
                  @click="typeEntityItemClick(item)"
                >
                  {{ formatShare(roundShare(item.generalShare)) }}
                </div>
              </div>
            </div>
            <div class="risk-exposure__row-cntrl">
              <GlArrow class="risk-exposure__row-cntrl-arrow" />
              <GlClose class="risk-exposure__row-cntrl-close" />
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <div
      v-else
      class="risk-exposure__empty"
    >
      <div>There is no data yet.</div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'
//Components
import GlBadgeInfo from '@/components/gl-badge-info'
import VueSkeletonLoader from 'skeleton-loader-vue'

// Utils
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import { formatByPrice } from '@/utils/format-by-price'
import {
  calculateScoreByRiskLevel,
  calculateRiskLevel,
} from '@/utils/risk-formatter'
import { goToEntity } from '@/utils/go-to-route'

// SVG
import GlArrow from '@/assets/svg/tools/arrow.svg?inline'
import GlClose from '@/assets/svg/tools/close.svg?inline'

// Libs
import _ from 'lodash'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { capitalizeFirstLetter } from '@/utils/text-formatter'

export default {
  components: {
    VueSkeletonLoader,
    GlBadgeInfo,
    GlArrow,
    GlClose,
  },
  mixins: [deviceWidthMixin],
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    pointerItem: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    full: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    twoLineView: {
      type: Boolean,
      default: true,
    },
    essential: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      percentsInPixels: 400,
      minWidth: 60,
    }
  },
  computed: {
    ...mapGetters('directions', [
      'getActiveExposurePart',
      'getActiveTypeEntityItem',
      'getActivePartBar',
    ]),
    dataTableResult() {
      if (Object.keys(this.getActiveTypeEntityItem).length) {
        return this.dataTable.filter(
          item =>
            this.formatLabelName(item) ===
            this.formatLabelName(this.getActiveTypeEntityItem),
        )
      }
      return !this.full ? this.dataTable.slice(0, this.perPage) : this.dataTable
    },
    isEntityLinksAvailable() {
      return (
        this.getActivePartBar.value === 'entity' ||
        this.getActivePartBar.value === 'owner'
      )
    },
    findMaxShare() {
      let maxShare = 0

      for (const item of this.dataTable) {
        const shareSum = item?.deepDepthShare + item?.generalShare
        if (shareSum > maxShare) {
          maxShare = shareSum
        }
      }

      return Math.round(maxShare * 100)
    },
  },
  watch: {
    getActiveExposurePart() {
      this.calculatePercentsInPixels()
    },
  },
  mounted() {
    window.addEventListener('resize', this.calculatePercentsInPixels)
    this.calculatePercentsInPixels()
  },
  destroyed() {
    window.removeEventListener('resize', this.calculatePercentsInPixels)
  },
  methods: {
    capitalizeFirstLetter,
    goToEntity,
    roundShare,
    formatShare,
    findColorByTypeScore,
    hex2rgba,
    formatByPrice,
    calculateScoreByRiskLevel,
    calculateRiskLevel,
    ...mapMutations('directions', [
      'SET_ACTIVE_EXPOSURE_PART',
      'SET_ACTIVE_TYPE_ENTITY_ITEM',
    ]),
    formatLabelName(item) {
      if (
        this.getActivePartBar.value === 'type' ||
        this.getActivePartBar.value === 'funds.type'
      ) {
        return this.formatLabel(item, 'funds.type')
      }
      if (
        this.getActivePartBar.value === 'entity' ||
        this.getActivePartBar.value === 'owner'
      ) {
        return this.formatLabel(item, 'owner')
      }
      if (this.getActivePartBar.value === 'riskLevel') {
        return this.formatLabel(item, 'riskLevel')
      }
    },
    getItemScore(item, withDefault = false) {
      if (this.getActivePartBar.value === 'riskLevel') {
        return this.calculateScoreByRiskLevel(item.riskLevel)
      }

      if (withDefault) return item?.funds?.default ? -1 : item?.funds?.score
      return item?.funds?.score ?? -1
    },
    formatLabel(item, target) {
      return _.get(item, target)
        ? _.get(item, target)
        : _.get(item, 'funds.name') || ''
    },
    isRowActive(item) {
      if (
        this.getActivePartBar.value === 'type' &&
        this.getActiveTypeEntityItem &&
        this.getActiveTypeEntityItem.funds &&
        this.getActiveTypeEntityItem.funds.type
      ) {
        return item.funds?.type === this.getActiveTypeEntityItem.funds?.type
      }
      if (
        this.getActivePartBar.value === 'entity' &&
        this.getActiveTypeEntityItem &&
        this.getActiveTypeEntityItem.owner
      ) {
        return item.owner === this.getActiveTypeEntityItem.owner
      }
    },
    clickOnLabel(item) {
      if (!item) return
      this.goToEntity(item.entityId)
    },

    isOneBar(item) {
      return !item?.deepDepthShare || !item?.generalShare
    },
    calculateWidth(item, percent, percentsInPixels) {
      const doubleMinWidth = this.isOneBar(item)
        ? this.minWidth * 2 + 2
        : this.minWidth

      const lineCof = this.twoLineView ? 2 : 1
      const percentWidth = Math.round(
        ((percentsInPixels * lineCof) / this.findMaxShare) * percent,
      )

      const finalWidth =
        percentWidth >= this.minWidth
          ? this.isOneBar(item)
            ? percentWidth + this.minWidth
            : percentWidth
          : doubleMinWidth
      return `${finalWidth}px`
    },
    classesForRiskExposureRow(item) {
      let classes = [`risk-exposure__row`]

      if (this.reverse) {
        classes.push('risk-exposure__row--reverse')
      }

      if (this.isRowActive(item)) {
        classes.push('risk-exposure__row--active')
      }

      classes.push(
        `risk-exposure__row--${this.calculateRiskLevel(
          this.getItemScore(item, true),
        )}`,
      )

      return classes
    },
    calculatePercentsInPixels() {
      this.$nextTick(() => {
        this.percentsInPixels =
          this.$refs.barsContainer?.offsetWidth / 2 - 30 || 400
      })
    },
    typeEntityItemClick(item) {
      if (this.getActivePartBar.value === 'type') {
        if (this.getActiveTypeEntityItem?.funds?.type === item?.funds?.type) {
          this.SET_ACTIVE_TYPE_ENTITY_ITEM({})
        } else {
          this.SET_ACTIVE_TYPE_ENTITY_ITEM(item)
        }
      }
      if (this.getActivePartBar.value === 'entity') {
        if (this.getActiveTypeEntityItem?.owner === item?.owner) {
          this.SET_ACTIVE_TYPE_ENTITY_ITEM({})
        } else {
          this.SET_ACTIVE_TYPE_ENTITY_ITEM(item)
        }
      }
    },
  },
}
</script>

<style>
.risk-exposure__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #595959;
  margin-top: 40px;
}
.risk-exposure__row {
  display: flex;
  align-items: center;
  padding: 7px 0;
  border-bottom: 1px solid var(--pale-grey);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.4s ease;
}
.risk-exposure__row:first-child {
  border-top: 1px solid var(--pale-grey);
}
.risk-exposure__row-badge {
  flex: 0 0 calc(50% - 44px);
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: calc(50% - 44px);
}
.risk-exposure__row-info {
  flex: 0 0 calc(50% + 44px);
  max-width: calc(50% + 44px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding-left: 44px;
  position: relative;
  left: 0;
  transition: left 0.4s ease;
  z-index: 2;
}
.risk-exposure__row-info-values {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 4px;
  white-space: nowrap;
}
.risk-exposure__row-info-values-price {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--Shadow-Mountain);
}
.risk-exposure__row-info-values-amount {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--Accent-Space-Cadet);
}
.risk-exposure__row-info-percents {
  display: flex;
  align-items: center;
  gap: 2px;
  max-width: 100%;
  font-size: 12px;
}
.risk-exposure__row-info-percents-item {
  max-width: 100% !important;
  padding: 6px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  color: var(--dark);
  white-space: nowrap;
}

/* control */
.risk-exposure__row-cntrl {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  cursor: pointer;
}
.risk-exposure__row-cntrl-arrow {
  display: block;
}
.risk-exposure__row-cntrl-close {
  display: none;
}

/* colors */
.risk-exposure__row--low .risk-exposure__row-info-percents-item--indirect {
  background-color: var(--Indication-Green);
}
.risk-exposure__row--low .risk-exposure__row-info-percents-item--direct {
  background-color: var(--Accent-Green);
  color: var(--white);
}
.risk-exposure__row--medium .risk-exposure__row-info-percents-item--indirect {
  background-color: var(--Indication-Yellow);
}
.risk-exposure__row--medium .risk-exposure__row-info-percents-item--direct {
  background-color: var(--color-tag-50);
}
.risk-exposure__row--high .risk-exposure__row-info-percents-item--indirect {
  background-color: var(--Indication-Red);
}
.risk-exposure__row--high .risk-exposure__row-info-percents-item--direct {
  background-color: var(--Accent-Scarled-Red);
  color: var(--white);
}
.risk-exposure__row--unknown .risk-exposure__row-info-percents-item--indirect {
  background: var(--stripped-gradient-unknown-light);
}
.risk-exposure__row--unknown .risk-exposure__row-info-percents-item--direct {
  background: var(--stripped-gradient-unknown);
}
.risk-exposure__row--pending .risk-exposure__row-info-percents-item--indirect {
  background-color: rgb(186, 201, 255);
}
.risk-exposure__row--pending .risk-exposure__row-info-percents-item--direct {
  background-color: rgb(90, 120, 234);
  color: var(--white);
}

/* active */
.risk-exposure__row:hover,
.risk-exposure__row--active {
  background-color: rgba(248, 250, 252, 1);
}
.risk-exposure__row:hover .risk-exposure__row-info,
.risk-exposure__row--active .risk-exposure__row-info {
  left: -28px;
}
.risk-exposure__row--active .risk-exposure__row-cntrl-arrow {
  display: none;
}
.risk-exposure__row--active .risk-exposure__row-cntrl-close {
  display: block;
}

/* reverse */
.risk-exposure__row--reverse.risk-exposure__row,
.risk-exposure__row--reverse .risk-exposure__row-info,
.risk-exposure__row--reverse .risk-exposure__row-info-percents {
  flex-direction: row-reverse;
}
.risk-exposure__row--reverse .risk-exposure__row-badge {
  align-items: end;
}
.risk-exposure__row--reverse .risk-exposure__row-info {
  padding-left: 0;
  padding-right: 44px;
}
.risk-exposure__row--reverse .risk-exposure__row-info-values {
  text-align: left;
}
.risk-exposure__row--reverse .risk-exposure__row-info-percents {
  text-align: right;
}
.risk-exposure__row--reverse .risk-exposure__row-cntrl {
  right: auto;
  left: 0;
}
.risk-exposure__row.risk-exposure__row--reverse:hover .risk-exposure__row-info,
.risk-exposure__row--reverse.risk-exposure__row--active
  .risk-exposure__row-info {
  left: 28px;
}
.risk-exposure__row--reverse .gl-badge-info {
  flex-direction: row-reverse;
}

/* two-line-view  */
.gl-scoring-bar-chart__two-line-view .risk-exposure__row {
  flex-direction: column;
  align-items: unset;
  gap: 8px;
}

.gl-scoring-bar-chart__two-line-view .risk-exposure__row-badge {
  flex: 1;
  max-width: 100%;
}
.gl-scoring-bar-chart__two-line-view
  .risk-exposure__row-badge
  .risk-exposure__row-info-values {
  flex-wrap: wrap;
}
.gl-scoring-bar-chart__two-line-view .risk-exposure__row-cntrl {
  top: unset;
  bottom: 0;
  transform: translate(0, -8px);
}

.gl-scoring-bar-chart__two-line-view .risk-exposure__row-info {
  flex: 1;
  max-width: 100%;
}

.gl-scoring-bar-chart__two-line-view
  .risk-exposure__row--reverse
  .risk-exposure__row-badge {
  align-items: start;
}
.gl-scoring-bar-chart__two-line-view
  .risk-exposure__row--reverse
  .gl-badge-info {
  justify-content: start;
}

.gl-scoring-bar-chart__two-line-view
  .risk-exposure__row--reverse
  .gl-badge-info {
  flex-direction: row;
}
</style>
